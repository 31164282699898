import { Action, Level, PermissionContext, PermissionSet, PermissionSetGroup, Resource } from './interfaces';

export class PermissionService {
  static setPermissions(permissionSet: PermissionSetGroup) {
    this._permissionSet = permissionSet;
  }

  static setPermissionContext(context: PermissionContext) {
    this._permissionContext = context;
  }

  static checkPermissions(
    resource: Resource,
    action: Action,
    level: Level = Level.GLOBAL,
    customerId?: string,
    appId?: string
  ) {
    if (Object.keys(this._permissionSet).length === 0) {
      return false;
    }

    const permissionCustomerId = customerId || this._permissionContext.customerId;
    const permissionAppId = appId || this._permissionContext.appId;

    switch (level) {
      case Level.APPLICATION:
        return (
          this._permissionSet[level] &&
          this._permissionSet[level][permissionAppId] &&
          !!this._permissionSet[level][permissionAppId][resource]?.includes(action)
        );
      case Level.CUSTOMER:
        return (
          this._permissionSet[level] &&
          this._permissionSet[level][permissionCustomerId] &&
          !!this._permissionSet[level][permissionCustomerId][resource]?.includes(action)
        );
      default:
        return (
          this._permissionSet[level] && !!(this._permissionSet[level] as PermissionSet)[resource]?.includes(action)
        );
    }
  }

  static hasPermission(resource: Resource, action: Action, customerId?: string, appId?: string) {
    const hasGlobalPerm = this.checkPermissions(resource, action, Level.GLOBAL);
    const hasAppPerm = this.checkPermissions(resource, action, Level.APPLICATION, customerId, appId);
    const hasCustomerPerm = this.checkPermissions(resource, action, Level.CUSTOMER, customerId, appId);
    return hasGlobalPerm || hasAppPerm || hasCustomerPerm;
  }

  static getPermissionContext = () => PermissionService._permissionContext;
  private static _permissionSet: PermissionSetGroup = {};
  private static _permissionContext: PermissionContext = { customerId: undefined, appId: undefined };
}
